@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: "Poppins", sans-serif;
}

.md-mobile-picker-header {
  font-size: 14px;
}

input.md-mobile-picker-input {
  color: initial;
  width: 100%;
  padding: 10px;
  margin: 6px 0 12px 0;
  border: 1px solid #ccc;
  background-color: #fffbef;
  border-radius: 0;
  font-family: arial, verdana, sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  -webkit-appearance: none;
}

.md-mobile-picker-button.mbsc-button {
  font-size: 13px;
  padding: 0 15px;
  line-height: 36px;
  float: right;
  margin: 6px 0;
  width: 100%;
}

.mbsc-col-no-padding {
  padding-left: 0;
}

.md-mobile-picker-box-label.mbsc-textfield-wrapper-box,
.md-mobile-picker-box-label .mbsc-textfield-wrapper-box,
.md-mobile-picker-inline {
  margin: 6px 0 12px 0;
}

.accordion {
  width: 50%;
  margin: auto;
  background: grey;
  padding: 5px;
}

.accordion__item {
  background: white;
  margin-bottom: 5px;
}

.accordion__item:last-child {
  margin-bottom: 0;
}

.accordion__header {
  padding: 10px;
  background: rgb(169, 169, 169);
  line-height: 20px;
}

.accordion__panel {
  padding: 10px;
  background: rgb(0, 0, 0);
}

.dropdown:hover .dropdown-content {
  display: block;
}


.mbsc-scroller{
  background-color: black;
}
.mbsc-ios.mbsc-scroller-pointer.mbsc-scroller, .mbsc-ios.mbsc-scroller-pointer.mbsc-scroller-inline, .mbsc-ios.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d{
  background-color: #FFFBEF !important;
}

/* if code */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}